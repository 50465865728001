import { 
    useContext,
    useState } from 'react';

import styles from './candidateInfo.module.scss';
import stylesHeader from '../../components/header/header.module.scss';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import closeIcon from '../../../assets/icons/close.svg';
import trashIcon from '../../../assets/icons/trash.svg';

import PersonalInfo from './components/personal_info/personal_info';
import WorkExperience from './components/work_experience/work_experience';
import AcademicInfo from './components/academic_info/academic_info';
import Certifications from './components/certifications/certifications';
import Languages, { validateLanguageRequest } from './components/languages/languages';
import Skills, { validateSkillRequest } from './components/skills/skills';
import { CandidateContext } from './context';
import { editPreviouJob, newPreviouJob } from '../../api/previousJobClient';
import { editEducation, newEducation } from '../../api/educationClient';
import { editCourse, newCourse } from '../../api/courseClient';
import { deleteSkillTag, deleteSkillTagValidation, getSkillTagByName, persistSkillTag, saveAll } from '../../api/skillClient';
import { deleteLanguage, editLanguage, newLanguage } from '../../api/languageClient';

import { 
    validateIsAlpha,
    validateNotNull,
    validatePhoneIsNumber,
    validateMinPhoneLength, 
    validateIsSelected} from '../../core/validators';
import { LanguagesLevel } from './components/languages/level_description';
import Software from './components/skills/software';
import { saveTalent, testConnection, updateTalent } from '../../api/talentClient';
import { objEquals } from '../../core/helper';
import { hardSkillsCatalogId, softSkillsCatalogId, softwareCatalogId } from '../../core/talent-module/hardcode';
import { SkillNew, validateNewSkill } from './components/skills/skillNew';
import { candidateFormsDesfase } from '../../core/hardcode';
import { SkillList } from './components/skills/SkillList';
import { SkillModal } from './components/skills/SkillModal';
import { defaultStringEmpty } from '../../core/talent-module/helper';
import { Assigment, validateAssigmentRequest } from './components/assigment/assigment';
import { validateVisibilityRequest, Visibility } from './components/visibility/visibility';
import { LoadedFrom } from './candidateInfo';
import { ProfileDetail } from '../catalogs/profiles/components/ProfileDetail';
import { saveViewers } from '../../api/talentInfoClient';
import moment from 'moment';

const CandidateForms = ({
    stepIndex,
    setStepIndex,
    displayNotification,
    resumeViewFlag,
    personalInfo,
    setPersonalInfo,
    appContext,
    catLevels,
    countries,
    catType,
    catTranslate,
    catCurrencies,
    catSeniority,
    catClient,
    catLeader,
    catViewer,
    from,
    catSkills,
    catSkillProfile,
    loadCatSkills
}) => {

    const [personalInfoErrors, setPersonalInfoErrors] = useState(false)
    const [descriptionFocus, setDescriptionFocus] = useState(false);
    const [seniorityFocus, setSeniorityFocus] = useState(false);
    const [photoError, setPhotoError] = useState(false);
    const [file, setFile] = useState(undefined);

    const personalInfoValidationFields = {
        firstName: [validateIsAlpha, validateNotNull],
        lastName: [validateIsAlpha, validateNotNull],
        position: [validateNotNull],
        seniority: [validateNotNull],
        description: [validateNotNull],
        phone: appContext.candidateMode ? [validatePhoneIsNumber, validateMinPhoneLength, validateNotNull] : [],
        state: appContext.candidateMode ? [validateNotNull] : [],
        country: appContext.candidateMode ? [validateIsSelected] : []
    }

    const {
        personalInfoCache,
        assigment,
        assigmentCache,
        setAssigmentErrors,
        visibilities,
        visibilitiesCache,
        setVisibilities,
        setVisibilitiesCache,
        setVisibilityErrors,
        workExperiences,
        workExperiencesCache,
        academics,
        academicsCache,
        certifications,
        certificationsCache,
        languages,
        languagesCache,
        setLanguageErrors,
        skills,
        setSkills,
        setSkillErrors,
        skillsCache,
        setSkillsCache,
        competition,
        setCompetition,
        competitionCache,
        setCompetitionErrors
    } = useContext( CandidateContext );

    const renderBackButton = (title, stepValue) => {
        return (stepIndex === 7 || (!resumeViewFlag && stepIndex >= 1 && stepIndex <= 6)) && (
        <div className={ styles.backbutton } onClick = { () => setStepIndex( stepValue ? stepValue : stepIndex - 1) }>
            <img src={ backArrowIcon } alt="" /><span>{ title ? title : appContext.t('button.back') }</span>
        </div>
    )}

    const formSections = [
        <PersonalInfo
            countries={ countries }
            catCurrencies={ catCurrencies }
            catSeniority={ catSeniority }
            catSkillProfile={ catSkillProfile }
            personalInfo={ personalInfo }
            setPersonalInfo={ setPersonalInfo }
            personalInfoErrors={ personalInfoErrors }
            file={ file }
            setFile={ setFile }
            descriptionFocus={ descriptionFocus }
            seniorityFocus={ seniorityFocus }
            photoError={ photoError }
            displayNotification={ displayNotification }
        />,
        <WorkExperience resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } />,
        <AcademicInfo resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } />,
        <Certifications resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } />,
        <Languages
            resumeViewFlag={ resumeViewFlag }
            renderBackButton={ renderBackButton }
            setStepIndex={ setStepIndex }
            catLanguages={ catTranslate }
            catLevels={ catLevels }
        />,
        <Skills
            resumeViewFlag={ resumeViewFlag }
            renderBackButton={ renderBackButton }
            setStepIndex={ setStepIndex }
            catSkills={ catSkills }
            loadCatSkills={ loadCatSkills }
        />,
        <Software
            resumeViewFlag={ resumeViewFlag }
            renderBackButton={ renderBackButton }
            setStepIndex={ setStepIndex }
            catSkills={ catSkills }
            loadCatSkills={ loadCatSkills }
        />,
        <LanguagesLevel renderBackButton={ renderBackButton } />,
        <SkillNew resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } catLanguages={ catTranslate } catType={ catType } />,
        <Assigment
            resumeViewFlag={ resumeViewFlag }
            renderBackButton={ renderBackButton }
            catClient={ catClient }
            catUser={ catLeader }
            personalInfo={ personalInfo }
            setPersonalInfo={ setPersonalInfo }
        />,
        <Visibility resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } catUser={ catViewer } />
    ]
    const optionalButtons = [
        null,
        appContext.isDesktopOrLaptop ? 'No cuento con experiencia' : 'Omitir',
        null,
        'No cuento con cursos o certificaciones',
        'Omitir',
        null,
        'Omitir',
        null,
        null
    ]

    const profileImageValidFormat = [
        "image/jpeg",
        "image/JPEG",
        "image/png",
        "image/PNG",
        "image/jpg",
        "image/JPG"
    ]

    const msgNotification = [
        {
            msgSave: null,
            msgCancel: null
        },
        {
            msgSave: appContext.t('candidate.modal.infoSaved'),
            msgCancel: appContext.t('candidate.resume.we.noSaveData')
        },
        {
            msgSave: appContext.t('candidate.modal.infoSaved'),
            msgCancel: appContext.t('candidate.resume.academic.noSaveData')
        },
        {
            msgSave: appContext.t('candidate.modal.infoSaved'),
            msgCancel: appContext.t('candidate.resume.courses.noSaveData')
        },
        {
            msgSave: appContext.t('candidate.modal.infoSaved'),
            msgCancel: appContext.t('candidate.resume.lang.noSaveData')
        },
        {
            msgSave: appContext.t('candidate.modal.infoSaved'),
            msgCancel: appContext.t('candidate.resume.skills.noSaveData')
        },
        {
            msgSave: appContext.t('candidate.modal.infoSaved'),
            msgCancel: appContext.t('candidate.resume.soft.noSaveData')
        },
        {},
        {
            msgSave: appContext.t('candidate.resume.skills.addForm.saved'),
            msgSave2: appContext.t('candidate.resume.soft.addForm.saved'),
            msgUpdate: appContext.t('update')
        },
        {
            msgSave: appContext.t('candidate.modal.infoSaved'),
            msgCancel: appContext.t('candidate.resume.soft.noSaveData')
        },
        {
            msgSave: appContext.t('candidate.modal.infoSaved'),
            msgCancel: null
        }
    ]

    const validatePersonalInfo = () => {
        let firstFocus = true;
        setDescriptionFocus(false);
        setSeniorityFocus(false);
        setPhotoError(false);
        const errorObject = {};
        var helper;
        // validating fields
        for(const field in personalInfoValidationFields) {
            if( field === 'seniority' && appContext.candidateMode ) {
                continue;
            }
            for (const validator in personalInfoValidationFields[field]) {
                helper = personalInfoValidationFields[field][validator](personalInfo[field])
                if (helper) {
                    errorObject[field] = helper
                    if( firstFocus ) {
                        if ( field === 'seniority' ) {
                            setSeniorityFocus(true);
                        } else if ( field === 'description') {
                            setDescriptionFocus(true);
                        } else if ( field === 'country' ) {
                        } else {
                            document.getElementsByName(field)[0].focus();
                        }
                        firstFocus = false;
                    }
                }
            }
        }

        // validando la imagen
        if (!file && !personalInfo.imgProfile) {
            errorObject["imgProfile"] = [appContext.t('validator.img.required')]
            setPhotoError(true);
        }

        if(file) {
            // validando el formato
            if (file.size > 10485760) {
                errorObject["imgProfile"] = [appContext.t('validator.img.size')]
                setPhotoError(true);
            }
            if (!profileImageValidFormat.includes(file.type)) {
                errorObject["imgProfile"] = [appContext.t('validator.img.format')]
                setPhotoError(true);
            }
        }

        if( personalInfo.salaryMin && personalInfo.salaryMax && Number(personalInfo.salaryMin) > Number(personalInfo.salaryMax) ) {
            errorObject["salary"] = [appContext.t('candidate.resume.info.salaryError')]
        }

        return errorObject
    }

    const handlePersonalInfoSubmit = () => {
        const validationErrors = validatePersonalInfo();
        if (Object.keys(validationErrors).length === 0) {
            appContext.showLoading(true, appContext.t('saving'), styles.no_scroll);
            const requestBody = {
                firstName: personalInfo.firstName,
                lastName: personalInfo.lastName,
                position: personalInfo.position,
                description: personalInfo.description,
                email: personalInfo.email,
                phone: personalInfo.phone,
                state: personalInfo.state,
                country: personalInfo.countryName,
                currency: personalInfo.currencyId,
                salaryMin: personalInfo.salaryMin ? personalInfo.salaryMin : 0,
                salaryMax: personalInfo.salaryMax ? personalInfo.salaryMax : 0,
                userId: appContext.userData.userProfile.id,
                jobSeniorityId: personalInfo.seniority,
                profileId: personalInfo.profileId
            }

            if (file) {
                requestBody["imgProfile"] = file
            }

            if (!appContext.talent) {
                saveTalent(requestBody, appContext.userData.token).then( response => {
                    if( response.status === 200 && response.data && response.data.code === 201 && response.data.result ) {
                        appContext.setTalent(response.data.result);
                        appContext.showLoading(false, '', styles.no_scroll);
                        displayNotification(appContext.t('update'), false, 5000);
                        gotoNext();
                    } else {
                        appContext.showLoading(false, '', styles.no_scroll);
                        displayNotification("Ha ocurrido un error al guardar la información", true, 5000);
                    }
                }).catch( error => {
                    console.log(error);
                    displayNotification(`Ha ocurrido un error al guardar la información [${error.message}]`, true, 5000);
                    appContext.showLoading(false, '', styles.no_scroll);
                })
            } else {
                requestBody["id"] = appContext.talent.id
                updateTalent(requestBody, appContext.candidateMode ? 3 : 4, appContext.userData.token).then( response => {
                    appContext.showLoading(false, '', styles.no_scroll);
                    displayNotification(appContext.t('update'), false, 5000);
                    gotoNext();
                }).catch( error => {
                    console.log(error);
                    appContext.showLoading(false, '', styles.no_scroll);
                })
            }
        } else {
            setPersonalInfoErrors(validationErrors);
        }
    }

    const deleteLanguages = () => {
        const languagesToDelete = languagesCache.filter( l => !languages.some( l2 => l2.languageId === l.languageId) );
        languagesToDelete.forEach( la => {
            deleteLanguage(appContext.talent.id, la.languageId, appContext.userData.userProfile.id, appContext.userData.token).then((response) => {
                if( response.data && response.data.code === 201 && response.data.result ) {
                    appContext.showLoading(false, '', styles.no_scroll);
                    displayNotification(msgNotification[stepIndex].msgSave, false, 3000);
                } else {
                    console.log('Error en el servicio', response);
                    appContext.showLoading(false, '', styles.no_scroll);
                }
            }).catch(error => {
                console.log('Error al ejecutar el servicio', error);
                appContext.showLoading(false, '', styles.no_scroll);
            })
        });
    }

    const saveSkills = (skills, categories) => {
        appContext.showLoading(true, appContext.t('saving'), styles.no_scroll);
        const skillsWithUser = [];
        skills.forEach( s => skillsWithUser.push({ ...s, 'userId': appContext.userData.userProfile.id }))
        const request = {
            'saveSkillTagRequestList': skillsWithUser,
            'categories': categories
        }
        saveAll(appContext.talent.id, request, appContext.userData.userProfile.id, appContext.userData.token).then((response) => {
            if( response.data && response.data.code === 202 ) {
                appContext.showLoading(false, '', styles.no_scroll);
                displayNotification(msgNotification[stepIndex].msgSave, false, 3000);
                setSkills([]);
                setSkillsCache([]);
                gotoNext();
            } else {
                console.log('Error en el servicio', response);
                appContext.showLoading(false, '', styles.no_scroll);
            }
        }).catch(error => {
            console.log('Error al ejecutar el servicio', error);
            appContext.showLoading(false, '', styles.no_scroll);
        })
    }

    const executeMultiPromises = (requestArray, personalInfo) => {
        appContext.showLoading(true, appContext.t('saving'), styles.no_scroll);
        Promise.all(requestArray).then( response => {
            appContext.showLoading(false, '', styles.no_scroll);
            setPersonalInfo(personalInfo);
            gotoNext();
        }).catch( error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(error);
        });
    }

    const checkSkillRepeat = () => {
        getSkillTagByName(competition.name, appContext.userData.token).then( response => {
            if(response.status === 200 && response.data && response.data.code) {
                if(response.data.code === 200) {
                    const totalRepeats = response.data.result.length;
                    appContext.setModalFlag(!appContext.modalFlag);
                    appContext.setModalContent(
                      <SkillModal
                        title={ appContext.t('candidate.resume.skills.addForm.modalRepeatTitle') }
                        message={ <p><b>{ appContext.t('candidate.resume.skills.addForm.modalRepeatMsg1').replace('%d', totalRepeats) }</b> { appContext.t('candidate.resume.skills.addForm.modalRepeatMsg2') }</p> }
                        labelBtnConfirm={ `${appContext.t('candidate.resume.skills.addForm.titleCreate')} ${appContext.t('candidate.resume.skills.addForm.titleSkill')}` }
                        skillTag={ competition }
                        confirmFuction={ saveNewSkill }
                      />
                    );
                } else if(response.data.code === 400) {
                    saveNewSkill(competition);
                }
            }
        }).catch( error => {
            console.log('Error al ejecutar el servicio', error);
        })
    }

    const saveNewSkill = skillTag => {
        appContext.showLoading(true, appContext.t('saving'), styles.no_scroll);
        persistSkillTag(appContext.userData.userProfile.id, skillTag, appContext.userData.token).then( response => {
            const result = response.data.result;
            setCompetition({ ...skillTag, tagId: result.id, tag: { id: result.id, description: result.name }} );
            const msgSuccess = skillTag.id ? msgNotification[stepIndex - candidateFormsDesfase].msgUpdate :
            (competition.categoryId !== softwareCatalogId ?
            msgNotification[stepIndex - candidateFormsDesfase].msgSave :
            msgNotification[stepIndex - candidateFormsDesfase].msgSave2);
            appContext.showLoading(false, '', styles.no_scroll);
            displayNotification(msgSuccess, false, 3000);
            gotoNext();
        }).catch( error => {
            console.log('Error al ejecutar el servicio', error);
            appContext.showLoading(false, '', styles.no_scroll);
        })
    }

    const deleteSkill = (skillTag) => {
        deleteSkillTag(appContext.userData.userProfile.id, skillTag.id, 'false', appContext.userData.token).then(response => {
            if( response.status === 200 && response.data && response.data.code === 200) {
                gotoNext();
                displayNotification(appContext.t('candidate.resume.skills.delete.deleted'));
            }
        }).catch(error => {
            console.log('Error to delete SkillTag', error);
        })
    }

    const updateAssigment = () => {
        appContext.showLoading(true, appContext.t('saving'), styles.no_scroll);
        const requestBody = {
            firstName: assigment.firstName,
            lastName: assigment.lastName,
            position: assigment.position,
            description: assigment.description,
            phone: assigment.phone,
            email: assigment.email,
            clientId: assigment.clientId ? assigment.clientId : null,
            assignmentStartDate: assigment.startDate && assigment.startDate.length > 0 ? moment(assigment.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
            assignmentEndDate: assigment.endDate && assigment.endDate.length > 0  ? moment(assigment.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
            hireDate: assigment.hireDate && assigment.hireDate.length > 0  ? moment(assigment.hireDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
            createdById: assigment.leaderId ? assigment.leaderId : null,
            areaLeader: assigment.leaderAreaValue.length > 0 ? assigment.leaderAreaValue : null,
            positionArea: assigment.area && assigment.area.length > 0 ? assigment.area : null
        }
        if (!appContext.talent) {
            saveTalent(requestBody, appContext.userData.token).then( response => {
                if( response.status === 200 && response.data && response.data.code === 201 && response.data.result ) {
                    appContext.setTalent(response.data.result);
                    appContext.showLoading(false, '', styles.no_scroll);
                    displayNotification(msgNotification[stepIndex - candidateFormsDesfase].msgSave, false, 3000);
                    gotoNext();
                } else {
                    appContext.showLoading(false, '', styles.no_scroll);
                }
            }).catch( error => {
                console.log('Error en el servicio', error);
                appContext.showLoading(false, '', styles.no_scroll);
            })
        } else {
            requestBody["id"] = appContext.talent.id
            updateTalent(requestBody, 2, appContext.userData.token).then( response => {
                appContext.showLoading(false, '', styles.no_scroll);
                displayNotification(msgNotification[stepIndex - candidateFormsDesfase].msgSave, false, 3000);
                gotoNext();
            }).catch( error => {
                console.log('Error en el servicio', error);
                appContext.showLoading(false, '', styles.no_scroll);
            })
        }
    }

    const updateViewers = () => {
        appContext.showLoading(true, appContext.t('saving'), styles.no_scroll);
        const viewersId = [];
        visibilities.forEach( v => viewersId.push(v.visibilityId))
        saveViewers(appContext.talent.id, appContext.userData.userProfile.id, { 'viewersId': viewersId}, appContext.userData.token).then((response) => {
            if( response.status === 200 && response.data && response.data.code === 202 ) {
                appContext.showLoading(false, '', styles.no_scroll);
                displayNotification(msgNotification[stepIndex - candidateFormsDesfase].msgSave, false, 3000);
                setVisibilities([]);
                setVisibilitiesCache([]);
                gotoNext();
            } else {
                console.log('Error en el servicio', response);
                appContext.showLoading(false, '', styles.no_scroll);
            }
        }).catch(error => {
            console.log('Error al ejecutar el servicio', error);
            appContext.showLoading(false, '', styles.no_scroll);
        })
    }

    const nextStep = isSave => {
        if( !isSave ) {
            gotoNext();
        } else {
            displayNotification(msgNotification[stepIndex].msgCancel, true, 3000);
        }
    }

    const onModalConfirm = () => {
        appContext.setModalFlag(false);
        handleOnClickSave(true)
    }

    const onModalDiscard = () => {
        appContext.setModalFlag(false);
        if ( stepIndex === 18 ) {
            setCompetition(undefined);
        }
        gotoNext();
    }

    const modalChangesBase = (title, child) => (
        <div className={ stylesHeader.error_modal }>
            <div className={ stylesHeader.wrapper }>
                <div className={ stylesHeader.content_title }>
                    <p className={ stylesHeader.title }>{ title }</p>
                    <img src={ closeIcon } alt='' onClick={ () => appContext.setModalFlag(false) } />
                </div>
                { child }
                <div className={ stylesHeader.content_buttons }>
                    <div className={ stylesHeader.buttons }>
                        <button className={ stylesHeader.cancel } onClick={ onModalDiscard }>{ appContext.t('candidate.modal.change.cancel') }</button>
                        <button className={ stylesHeader.confirm } onClick={ onModalConfirm }>{ appContext.t('candidate.modal.change.save') }</button>
                    </div>
                </div>
            </div>
        </div>
    )

    const renderModalChanges = () => modalChangesBase(appContext.t('candidate.modal.change.title'),
        <p>
            <b>{ appContext.t('candidate.modal.change.msg1') } { `${personalInfo.firstName} ${personalInfo.lastName}` }</b>
            <br />
            { appContext.t('candidate.modal.change.msg2') }
        </p>
    )

    const renderModalChangeSkill = label => modalChangesBase(
        appContext.t( competition.id === 0 ? `candidate.resume.${ label }.addForm.modalTitleNew` : 'candidate.modal.change.title'),
        <p>
            { competition.id !== 0 && <b>{ appContext.t(`candidate.resume.${ label }.addForm.modalMsgEdit`) }</b> }
            { competition.id !== 0 && <br /> }
            { appContext.t(`candidate.resume.${ label }.addForm.modalMsg`) }
        </p>
    )

    const onCancel = () => {
        if( (stepIndex === 0 && !objEquals(personalInfo, personalInfoCache))
        || (stepIndex === 1 && !objEquals(workExperiencesCache, workExperiences))
        || (stepIndex === 2 && !objEquals(academicsCache, academics))
        || (stepIndex === 3 && !objEquals(certificationsCache, certifications))
        || (stepIndex === 4 && !objEquals(languages, languagesCache))
        || ((stepIndex === 5 || stepIndex === 6) && !objEquals(skillsCache, skills))
        || (stepIndex === 19 && !objEquals(assigment, assigmentCache))
        || (stepIndex === 20 && !objEquals(visibilities, visibilitiesCache))
        ) {
            appContext.setModalFlag(!appContext.modalFlag);
            appContext.setModalContent(renderModalChanges());
        } else if (stepIndex === 18 && ( competition.id === 0 || !objEquals(competitionCache, competition))) {
            appContext.setModalFlag(!appContext.modalFlag);
            const label = competition.category.id !== softwareCatalogId ? 'skills' : 'soft'
            appContext.setModalContent(renderModalChangeSkill(label));
        } else if (stepIndex === 18 && ( competition.id !== 0 && objEquals(competitionCache, competition))) {
            setCompetition(null);
            gotoNext();
        } else {
            gotoNext();
        }
    }

    const gotoNext = () => {
        // displayNotification(null, true, 1);
        setAssigmentErrors({});
        setLanguageErrors([]);
        setVisibilityErrors([]);
        setCompetitionErrors({});
        if (!resumeViewFlag && stepIndex < 6) {
            setStepIndex(stepIndex + 1)
        } else if ( stepIndex === 18 ) {
            if( appContext.talent ) {
                setStepIndex( competition.category.id !== softwareCatalogId ? 5 : 6);
            } else if( from === LoadedFrom.SKILLS ) {
                appContext.setHomeContent(<SkillList />)
            } else if( from === LoadedFrom.PROFILES ) {
                appContext.setHomeContent(<ProfileDetail />)
            }
        } else {
            setStepIndex(8);
        }
        appContext.contentScrollUp();
        let menuNavigation = [ ...appContext.menuNavigation ];
        menuNavigation.pop();
        appContext.setMenuNavigation(menuNavigation);
    }

    const handleOnClickSave = (isSave) => {
        testConnection(appContext.userData.token).then((response) => {
            const requestArray = [];
            if ( stepIndex === 0 ) {
                handlePersonalInfoSubmit()
                return
            } else if ( stepIndex === 1 ) {
                if( workExperiences.length === 0 ) {
                    nextStep(isSave);
                } else if (isSave) {
                    workExperiences.forEach( we => {
                        if (we.id === 0) {
                            requestArray.push(newPreviouJob(appContext.talent.id, we, appContext.userData.userProfile.id, appContext.userData.token));
                        } else {
                            requestArray.push(editPreviouJob(appContext.talent.id, we, appContext.userData.userProfile.id, appContext.userData.token));
                        }
                    } )
                    let pi = { ...personalInfo };
                    pi.previousJobs = workExperiences
                    executeMultiPromises(requestArray, pi);
                }
            } else if ( stepIndex === 2 ) {
                if( academics.length === 0 ) {
                    nextStep(isSave);
                } else if (isSave) {
                    academics.forEach( ac => {
                        ac["description"] = appContext.t('noDescDefault')
                        if (ac.courseId === 0) {
                            requestArray.push(newEducation(appContext.talent.id, ac, appContext.userData.userProfile.id, appContext.userData.token));
                        } else {
                            requestArray.push(editEducation(appContext.talent.id, ac, appContext.userData.userProfile.id, appContext.userData.token));
                        }
                    } )
                    let pi = { ...personalInfo };
                    pi.educations = academics
                    executeMultiPromises(requestArray, pi);
                }
            } else if ( stepIndex === 3 ) {
                if( certifications.length === 0 ) {
                    nextStep(isSave);
                } else if (isSave) {
                    certifications.forEach( ce => {
                        ce["description"] = appContext.t('noDescDefault')
                        if(ce.courseId === 0) {
                            requestArray.push(newCourse(appContext.talent.id, ce, appContext.userData.userProfile.id, appContext.userData.token));
                        } else {
                            requestArray.push(editCourse(appContext.talent.id, ce, appContext.userData.userProfile.id, appContext.userData.token));
                        }
                    })
                    let pi = { ...personalInfo };
                    pi.courses = certifications
                    executeMultiPromises(requestArray, pi);
                }
            } else if ( stepIndex === 4 ) {
                if( languages.length === 0 ) {
                    nextStep(isSave);
                } else if (isSave) {
                    const errorValidations = validateLanguageRequest(languages);
                    const errors = Object.keys(errorValidations);
                    const errorLang = errors.filter( w => w.includes('language_')).length > 0;
                    const errorLevel = errors.filter( w => w.includes('langLevel_')).length > 0;
                    if ( !(errorLang || errorLevel) ) {
                        languages.forEach( la => {
                            if( la.id ) {
                                requestArray.push(editLanguage(appContext.talent.id, la, appContext.userData.userProfile.id, appContext.userData.token));
                            } else {
                                requestArray.push(newLanguage(appContext.talent.id, la, appContext.userData.userProfile.id, appContext.userData.token));
                            }
                        });
                        deleteLanguages();
                        let pi = { ...personalInfo };
                        pi.languages = languages
                        executeMultiPromises(requestArray, pi);
                    } else {
                        setLanguageErrors(errorValidations);
                    }
                }
            } else if ( stepIndex === 5 || stepIndex === 6 ) {
                if( skillsCache.length === 0 ) {
                    nextStep(isSave);
                } else if (isSave) {
                    const totalHardSkills = skillsCache.filter( s => s.categoryId === hardSkillsCatalogId).length;
                    const totalPersonalSkills = skillsCache.filter( s => s.categoryId === softSkillsCatalogId).length;
                    if( stepIndex === 5 && ( totalHardSkills < 5 || totalPersonalSkills < 5 ) ) {
                        displayNotification(appContext.t('candidate.resume.skills.requireds'), true, 0);
                    } else {
                        const categories = stepIndex === 5 ? [hardSkillsCatalogId, softSkillsCatalogId] : ( stepIndex === 6 ? [softwareCatalogId] : [] );
                        const skillsToSave = stepIndex === 5 ? skillsCache.filter( s => s.categoryId === hardSkillsCatalogId || s.categoryId === softSkillsCatalogId ) : 
                            ( stepIndex === 6 ? skillsCache.filter( s => s.categoryId === softwareCatalogId ) : [] );
    
                        // validando antes de guardar
                        // revisando si es software o skills
                        let validationDictHelper = {}
                        if (stepIndex === 6) {
                            validationDictHelper = validateSkillRequest(softwareCatalogId, skillsToSave, appContext)
                        } else {
                            validationDictHelper = Object.assign(
                                validateSkillRequest(hardSkillsCatalogId, skillsToSave, appContext),
                                validateSkillRequest(softSkillsCatalogId, skillsToSave, appContext));
                        }
                        if (Object.keys(validationDictHelper).length === 0) {
                            // console.log('Snapshot', skills);
                            // console.log('Cache', skillsCache);
                            saveSkills(skillsToSave, categories);
                        }
                        setSkillErrors(validationDictHelper);
                    }
                }
            } else if ( stepIndex === 18 ) {
                const errorValidations = validateNewSkill(competition);
                let hasErrors = Object.keys(errorValidations).length !== 0;
                if (!hasErrors) {
                    if( competition.id ) {
                        saveNewSkill(competition);
                    } else {
                        checkSkillRepeat();
                    }
                } else {
                    setCompetitionErrors(errorValidations);
                }
            } else if ( stepIndex === 19 ) {
                const errorValidations = validateAssigmentRequest(assigment);
                let hasErrors = Object.keys(errorValidations).length !== 0;
                if (!hasErrors) {
                    updateAssigment();
                } else {
                    setAssigmentErrors(errorValidations);
                }
            } else if ( stepIndex === 20 ) {
                if( visibilities.length === 0 ) {
                    nextStep(isSave);
                } else if (isSave) {
                    const errorValidations = validateVisibilityRequest(visibilities);
                    if (!(Object.keys(errorValidations).filter( w => w.includes('visibility_')).length > 0)) {
                        updateViewers();
                        setVisibilityErrors([]);
                    } else {
                        setVisibilityErrors(errorValidations);
                    }
                }
            } else {
                gotoNext();
            }
        }).catch( error => {
            if( error.code === 'ERR_NETWORK' ) {
                displayNotification(null, false, false, true);
            }
        })
    }

    const onClickDeleteCompetition = skill => {
        deleteSkillTagValidation(skill.id, appContext.userData.token).then( response => {
            if( response.status === 200 && response.data) {
                if( response.data.code === 400 ) {
                    appContext.setModalFlag(!appContext.modalFlag);
                    appContext.setModalContent(
                        <SkillModal
                            title={ appContext.t('candidate.resume.skills.delete.modalConfirmTitle') }
                            message={ response.data.result }
                            skillTag={ skill }
                        />
                    );
                } else if( response.data.code === 200 ) {
                    appContext.setModalFlag(!appContext.modalFlag);
                    appContext.setModalContent(
                        <SkillModal
                            title={ appContext.t('candidate.resume.skills.delete.modalConfirmTitle') }
                            message={ <p><b>{ defaultStringEmpty(skill.name) }</b> { appContext.t('candidate.resume.skills.delete.modalConfirmMsg') }</p> }
                            labelBtnConfirm={ appContext.t('candidate.resume.skills.delete.modalConfirmBtn') }
                            skillTag={ skill }
                            deleteFunction={ deleteSkill }
                        />
                    );
                }
            }
        }).catch( error => {
            console.log('Error to delete competition', error);
        })
    }

    const showCancelButton = (
        (stepIndex === 1 && workExperiences.length === 0 && !resumeViewFlag)
        || (stepIndex === 2 && academics.length === 0 && !resumeViewFlag)
        || (stepIndex === 3 && certifications.length === 0 && !resumeViewFlag)
        || (stepIndex === 4 && languages.length === 0 && !resumeViewFlag)
        || (stepIndex === 5 && skills.length === 0 && !resumeViewFlag)
    )

    const styleButtons = appContext.isDesktopOrLaptop ? undefined : { width:  optionalButtons[stepIndex] && showCancelButton ? '50%' : '100%' }

    return (
        <div className={ styles.candidate_columns }>
            <div className={ styles.candidate_form }>
                { appContext.isDesktopOrLaptop && renderBackButton( null, stepIndex === 7 ? 4 : null) }
                { formSections[stepIndex >= candidateFormsDesfase ? stepIndex - candidateFormsDesfase : stepIndex] }
                { appContext.isDesktopOrLaptop && stepIndex !== 7 && <hr className={ stepIndex > 18 ? styles.margin_long : undefined } /> }
                <div className={ styles.buttons_spaced }>
                    <div className={ styles.buttons }>
                        {
                            optionalButtons[stepIndex] && showCancelButton && (
                                <button className={ styles.cancel_button } style={ styleButtons } onClick={() => handleOnClickSave(false)}>{ optionalButtons[stepIndex] }</button>
                            )
                        }
                        {
                            (stepIndex <= 6 || stepIndex >= candidateFormsDesfase) && resumeViewFlag && (
                                <button className={ styles.cancel_button } style={ styleButtons } onClick={ onCancel }>{ appContext.t('button.cancel') }</button>
                            )
                        }
                        { stepIndex !== 7 && (<button className={ styles.continue_button } style={ styleButtons } onClick={() => handleOnClickSave(true)}>{ (appContext.talent || (stepIndex === 18 && competition.id)) ? appContext.t('button.save') : appContext.t('button.create') }</button>) }
                    </div>
                    {
                        stepIndex === 18 && !appContext.talent && competition.id && from === LoadedFrom.SKILLS ? (
                            <div className={ styles.trash } onClick={() => onClickDeleteCompetition(competition)}>
                                { appContext.t('candidate.resume.skills.delete.labelDelete') }
                                <img  src={ trashIcon } alt='' />
                            </div>
                        ) : undefined
                    }
                </div>
            </div>
        </div>
    )
}

export default CandidateForms;