import { createContext, useRef, useState } from "react";
import { TalentList } from "../../talents/components/list/TalentList";
import { useNavigate } from "react-router-dom";
import notificationStyles from '../../../../app/components/notification/notification.module.scss';
import notificationTalentStyles from '../../../../app/components/talent-module/notification/notification.module.scss';
import Notification from "../../../components/notification/notification";
import NotificationTalent from "../../../components/talent-module/notification/Notification";
import { useMediaQuery } from "react-responsive";
import ModalBase from "../../../components/modal/modal_base";

import admirationIcon from "../../../../assets/icons/admiration_icon.svg";
import confirmationIcon from "../../../../assets/icons/confirm_icon.svg";
import { useTranslation } from "react-i18next";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    })

    const navigate = useNavigate();
    const { t } = useTranslation();
    const contentRef = useRef();
    const contentScrollUp = () => {
        contentRef.current.scroll({
            top: 0,
            behavior: 'smooth'
        })
    }

    const [loadingData, setLoadingData] = useState({ flag: false, loadingLabel: '' });
    const [modalFlag, setModalFlag] = useState(false);
    const [modalContent, setModalContent] = useState(undefined);
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userInfo')));
    const [recoveryInfo, setRecoveryInfo] = useState({})
    const [stepPswdRecoveryIndex, setStepPswdRecoveryIndex] = useState(0);
    const [candidateMode, setCandidateMode] = useState(true);
    const [homeContent, setHomeContent] = useState(<TalentList />);
    const [notificationData, setNotificationData] = useState({
        notificationStyle: notificationStyles.notification_wrapper
    });
    // Context Talent
    const [currentPage, setCurrentPage] = useState(0);
    const [filter, setFilter] = useState('');
    const [filterType, setFilterType] = useState([]);
    const [visibilityFilter, setVisibilityFilter] = useState([]);
    const [transFilter, setTransFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState();
    const [langFilter, setLangFilter] = useState();
    const [levelFilter, setLevelFilter] = useState();
    const [clientFilter, setClientFilter] = useState([]);
    const [talent, setTalent] = useState();
    const [notificationTalentData, setNotificationTalentData] = useState({
        notificationStyle: notificationStyles.notification_wrapper,
        closeIcon: true
    });
    const [showMenu, setShowMenu] = useState(true);
    const [menuOptSelected, setMenuOptSelected] = useState(t('menu.candidate.opt1'));
    const [menuNavigation, setMenuNavigation] = useState([{ label: t('menu.candidate.opt1'), component: <TalentList /> }])

    const notification = <Notification data={ notificationData } />;
    const notificationTalent = <NotificationTalent data={ notificationTalentData } />;
    const modal = <ModalBase />

    const showLoading = (flag, loadingLabel, overflowClass) => {
        if( flag ) {
            document.body.classList.add( overflowClass );
        } else {
            document.body.classList.remove( overflowClass );
        }
        setLoadingData({ 'flag': flag, 'loadingLabel': loadingLabel });
    }

    const displayNotification = (message, isErrorConection, display) => {
        window.scrollTo(0, 0);
        const styleShow = isErrorConection ? `${notificationStyles.notification_wrapper_error} ${notificationStyles.active}` : `${notificationStyles.notification_wrapper} ${notificationStyles.active}`;
        setNotificationData({
            notificationStyle: styleShow,
            message: message,
            isDisplay: display && display === 'hide'  ? false : true,
            isErrorConection: isErrorConection
        });

        if( !isErrorConection ) {
            setTimeout(() => {
                setNotificationData({
                    notificationStyle: notificationStyles.notification_wrapper,
                    message: message,
                    isDisplay: false
                })
            }, 5000);
        }
    }

    const displayNotificationTalent = (message, isWarn, topPosition, withCloseIcon, timer) => {
        const style = isWarn ? notificationTalentStyles.notification_wrapper_warning : notificationTalentStyles.notification_wrapper;
        const icon = isWarn ? admirationIcon : confirmationIcon;
        const notificationData = {
            notificationStyle: style,
            message: message,
            icon: icon,
            top: topPosition,
            closeIcon: withCloseIcon
        }
        setNotificationTalentData({
            ...notificationData,
            notificationStyle: ( `${ style } ${notificationTalentStyles.active}` )
        });
        if( timer ) {
            setTimeout(() => {
                setNotificationData(notificationData)
            }, timer);
        }
    }

    const cleanFilter = () => {
        setFilter('');
        setStatusFilter([]);
        setDateFilter(null);
        setLangFilter(null);
        setLevelFilter(null);
        setClientFilter([]);
        setFilterType([]);
        setVisibilityFilter([]);
        setTransFilter([]);
        setCurrentPage(0);
    }

    return (
        <AppContext.Provider value={{
            t,
            modalFlag,
            setModalFlag,
            loadingData,
            showLoading,
            modalContent,
            modal,
            setModalContent,
            navigate,
            displayNotification,
            notification,
            userData,
            setUserData,
            isDesktopOrLaptop,
            recoveryInfo,
            setRecoveryInfo,
            stepPswdRecoveryIndex,
            setStepPswdRecoveryIndex,
            currentPage,
            setCurrentPage,
            filter,
            setFilter,
            filterType,
            setFilterType,
            visibilityFilter,
            setVisibilityFilter,
            transFilter,
            setTransFilter,
            statusFilter,
            setStatusFilter,
            dateFilter,
            setDateFilter,
            langFilter,
            setLangFilter,
            levelFilter,
            setLevelFilter,
            clientFilter,
            setClientFilter,
            talent, 
            setTalent,
            notificationTalent,
            displayNotificationTalent,
            homeContent,
            setHomeContent,
            menuOptSelected,
            setMenuOptSelected,
            contentRef,
            contentScrollUp,
            menuNavigation,
            setMenuNavigation,
            showMenu,
            setShowMenu,
            setNotificationData,
            cleanFilter,
            candidateMode,
            setCandidateMode
        }}>
            { children }
        </AppContext.Provider>
    )
}