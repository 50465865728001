import styles from '../../../candidates/candidateInfo.module.scss';
import stylesC from '../../checkup.module.scss';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';
import { ClimateDetail } from './ClimateDetail';
import { ClimateResume } from './ClimateResume';
import { validateNotNull } from '../../../../core/validators';

export const validateClimate = (tap, checkup, hasFollowItems) => {
    const checkupErrors = {};
    let firstError = false;
    if( tap === 0 || (tap === 1 && hasFollowItems) ) {
        const followToken = 'follow';
        checkup.followUps.forEach( (c, index) => {
            const commentValidation = validateNotNull(c.value.comment);
            if( commentValidation ) {
                checkupErrors[`${followToken}_${index}`] = commentValidation;
                if(!firstError) {
                    checkupErrors[`${followToken}_${index}_focus`] = true;
                    firstError = true;
                }
            }
        })
    }
    if( tap === 1 ) {
        const commentToken = 'comment';
        checkup.comments.forEach( (c, index) => {
            const commentValidation = validateNotNull(c.value.comment);
            if( commentValidation ) {
                checkupErrors[`${commentToken}_${index}`] = commentValidation;
                if(!firstError) {
                    checkupErrors[`${commentToken}_${index}_focus`] = true;
                    firstError = true;
                }
            }
        });
    }
    return checkupErrors;
}

export const Climate = ({
    appContext,
    tap,
    checkup,
    checkupErrors,
    changeTap,
    onChangeInput,
    catClasification,
    catExistRisk,
    catLevelSatisfaction,
    modeResume,
    hasFollowItems
}) => {

    const renderMainRow = () => !(tap === 1 && checkup.id) && (
        <div className={ styles.section_header}>
            <div className={ styles.section_title }>
                <div className={ styles.decorator }></div>
                { appContext.t('checkUp.climate.title') }
            </div>
            { tap !== 1 &&
                <div className={ styles.button_container }>
                    <div className={ styles.vertical_separator }></div>
                    <button className={ styles.add_info } onClick={() => changeTap(1)}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            }
        </div>
    )

    return (
        <div className={ `${styles.form_container} ${stylesC.checkup}` }>
            { !modeResume && <p className={ styles.title }>{ appContext.t( checkup.id && tap === 1 ? 'checkUp.climate.title' : 'candidate.resume.review.checkUpAdd') }</p> }
            { renderMainRow() }
            {
                tap === 1 ?
                <ClimateDetail
                    appContext={appContext}
                    checkup={checkup}
                    checkupErrors={checkupErrors}
                    onChangeInput={onChangeInput}
                    catClasification={catClasification}
                    catExistRisk={catExistRisk}
                    catLevelSatisfaction={catLevelSatisfaction}
                    modeResume={modeResume}
                    hasFollowItems={hasFollowItems}
                /> :
                <ClimateResume
                    appContext={appContext}
                    checkup={checkup}
                    checkupErrors={checkupErrors}
                    onChangeInput={onChangeInput}
                    catLevelSatisfaction={ catLevelSatisfaction }
                    tap={tap}
                    changeTap={changeTap}
                    modeResume={modeResume}
                />
            }
        </div>
    )
}
