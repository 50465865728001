import { useContext } from 'react';
import { AppContext } from '../../context/AppProvider';
import styles from '../../candidateInfo.module.scss';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';

export const CertificationResume = (
    {
        personalInfo,
        updateView,
        getParsedDate
    }
) => {

    const appContext = useContext(AppContext);

    // TODO Replace by catalog
    const courseTypes = {
        1001900001: "Certificación",
        1001900006: "Curso",
        1001900002: "Diplomado"
    }

    return (
        <div className={ styles.section }>
            <div className={ styles.section_header}>
                <div className={ styles.section_title }>
                    <div className={ styles.decorator }></div>
                    { appContext.t('candidate.resume.courses.title') }
                </div>
                <div className={ styles.button_container }>
                    <div className={ styles.vertical_separator }></div>
                    <button className={ styles.add_info } onClick={() => updateView(3, appContext.t('candidate.resume.courses.title'))}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            </div>
            <div className={ styles.cards_wrapper }>
                {
                    personalInfo.courses?.map((courseInfo, index) => {
                        const periodStart = courseInfo.startDate ? getParsedDate(courseInfo.startDate) : appContext.t('noData.unspec');
                        const periodEnd = courseInfo.endDate ? getParsedDate(courseInfo.endDate) : appContext.t('noData.inProg');
                        const labelPeriod = courseInfo.startDate && courseInfo.endDate ? 'candidate.resume.courses.period'
                        : ( !courseInfo.startDate && courseInfo.endDate ? 'noData.dateEnd' : 'candidate.resume.courses.period' )
                        const period = ((courseInfo.startDate && courseInfo.endDate) || (courseInfo.startDate && !courseInfo.endDate)) ? `${periodStart} - ${periodEnd}` : periodEnd;
                        return(
                            <div key={ index } className={ styles.info_card }>
                                <div className={ styles.card_title}>
                                    <label>{ courseTypes[courseInfo.typeId]  }</label>
                                    <label className={ styles.hidden_mobile }>-</label>
                                    <label>{ courseInfo.name }</label>
                                    <label className={ styles.hidden_mobile }>-</label>
                                    <label>{ courseInfo.school }</label>
                                </div>
                                <div>
                                    { courseInfo.certificateNumber && <p className={ styles.info_description}>{ appContext.t('candidate.resume.courses.certNum') }: { courseInfo.certificateNumber }</p> }
                                    <p className={ styles.info_date }>{ appContext.t(labelPeriod) }: { period }</p>
                                    { courseInfo.validityDate && <p className={ styles.info_date }>{ appContext.t('candidate.resume.courses.effective') }: { `${(courseInfo.validityDate ? getParsedDate(courseInfo.validityDate) : appContext.t('noData.notvalid') )}`  }</p> }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}