import { appApi, createJsonAccessToken } from "./apiInstance";

const apiUrl = process.env.REACT_APP_GATEWAY_URL;
const vacancyModule = 'vacancies';
const referralModule = 'referrals';

export const getVacancies = (accessToken, filter, clientFilter, statusFilter, visibilityFilter, page=0, size=10, sorter = 'creationDate,DESC') => {
    const filterParam = filter ? `&filter=${filter}` : '';
    let clientParam = '';
    if( clientFilter ) {
        clientFilter.forEach( c => clientParam += `&clientIds=${c.id}` )
    }
    let filterStatus = '';
    if( statusFilter && statusFilter.length > 0 ) {
        statusFilter.forEach( s => filterStatus += `&statusIds=${s.id}` )
    }
    let publicParam = '';
    if( visibilityFilter ) {
        visibilityFilter.forEach( c => publicParam += `&visibilities=${c.optionName}` )
    }
    return appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${vacancyModule}?page=${page}&size=${size}&sortFields=${sorter}${filterParam}${clientParam}${filterStatus}${publicParam}`);
}

export const getReferrals = (accessToken, filter, dateFilter, page=0, size=10, sorter='createdDate', direction='desc') => {
    const filterParam = filter ? `&filter=${filter}` : '';
    const dateFilterParam = dateFilter && dateFilter.range ? `&startDate=${dateFilter.range.start}&endDate=${dateFilter.range.end}` : '';
    return appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${referralModule}?paginated=true&page=${page}&size=${size}&sortBy=${sorter}&sortDir=${direction}${filterParam}${dateFilterParam}`);
}

export const getReferral = (email, vacandyId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${referralModule}/${email}/${vacandyId}`);