import { useContext } from 'react';
import styles from './../vacancy.module.scss';
import { AppContext } from '../../candidates/context/AppProvider';
import editIcon from '../../../../assets/icons/orange_pencil.svg';
import locationIcon from '../../../../assets/icons/location.svg';
import areaIcon from '../../../../assets/icons/area.svg';
import personIcon from '../../../../assets/icons/person.svg';
import { defaultStringEmpty } from '../../../core/talent-module/helper';
import { Select } from '../../../components/talent-module/searcher/Select';

export const VacancyDetail = ({
    vacancy,
    catStatus
}) => {
    const appContext = useContext(AppContext);

    const renderHeader = (title) => (
        <div className={ styles.header }>
            <div className={ styles.container }>
                <div className={ styles.orange_bar } />
                <span className={ `${styles.purple_title} ${styles.purple_title_20px}` }>{ title }</span>
            </div>
            <div className={ styles.thin_bar } />
            <div className={ styles.container }>
                <button className={ styles.add_info } onClick={() => console.log('Click Detail')}>
                    { appContext.t('candidate.resume.edit') }
                    <img src={ editIcon } alt="" />
                </button>
            </div>
        </div>
    )

    const renderSpanRow = (label, value) => <div className={ styles.span_row }><span className={styles.black_label}>{label}:</span><span>{value}</span></div>

    const renderVacancyDetail = (icon, label, value) => (
        <div className={ styles.detail_info }>
            <img src={ icon } width={24} height={24} alt="" />
            { renderSpanRow(appContext.t(label), value) }
        </div>
    )

    const renderVacancyTitle = () => (
        <div className={ styles.title }>
            <span className={ styles.title_label }>{ defaultStringEmpty(vacancy.vacancyProfileName) }</span>
            <div className={ styles.detail }>
                <span className={`${styles.fontwhite} ${styles.border} ${vacancy.visibility.includes('No') ? styles.border_gray : styles.border_green}`}>{ vacancy.visibility }</span>
                <div className={ styles.thin_bar } />
                { renderVacancyDetail(locationIcon, 'candidate.resume.info.location', `${defaultStringEmpty('Ciudad de México')}, ${defaultStringEmpty('México')}`) }
                <div className={ styles.thin_bar } />
                { renderVacancyDetail(areaIcon, 'candidate.resume.assignment.labelArea', defaultStringEmpty('Tecnología')) }
                <div className={ styles.thin_bar } />
                { renderVacancyDetail(personIcon, 'candidate.resume.postulation.recruiter', defaultStringEmpty(vacancy.authorName)) }
            </div>
        </div>
    )

    const renderSection = (child, subtitle) => (
        <div className={ styles.section }>
            { subtitle && <span className={ styles.purple_title }>{ appContext.t(subtitle) }</span> }
            { child }
        </div>
    )

    const renderList = list => list && list.length > 0 ? <ul>{ list.map((item, index) => (
        <li key={index}>{ typeof item === 'string' ? item : renderSpanRow(item.label, item.value) }</li>
    ))}</ul> : appContext.t('noData.unspec')

    const lista = [
        'Is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
        'When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.',
        'But also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.',
        'And more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        'Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.',
        'Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage.'
    ]

    const listEmpty = [];

    const listWorkModality = [
        { label: appContext.t('vacancy.info.modality.address'), value: defaultStringEmpty('Puerta Polanco: Blvd. Manuel Ávila Camacho No. 5, Col. Lomas de Sotelo, Naucalpan de Juárez, Parque Toreo.') },
        { label: appContext.t('vacancy.info.modality.horary'), value: defaultStringEmpty('Tiempo completo - Lunes a Viernes de 9:00 a 18:00.') },
        { label: appContext.t('vacancy.info.modality.detail'), value: defaultStringEmpty('Híbrida - Deberás contar con disponibilidad para asistir a sitio de acuerdo a las necesidades del proyecto.') },
    ]

    const listHistory = [
        { label: appContext.t('vacancy.info.history.experience'), value: defaultStringEmpty('3-4 años de experiencia en puesto similar.') },
        { label: appContext.t('vacancy.info.history.education'), value: defaultStringEmpty('Ing. en Sistemas, Científico de Datos, Lic. en Matemáticas, o afín.') }
    ]

    const languages = [
        { label: 'Inglés B2 - Intermedio Avanzado', value: 'Tengo la fluidez necesaria para comunicarme sin esfuerzo con hablantes nativos de manera oral y escrita.' }
    ]

    const renderStatus = () => (
        <div className={ styles.header }>
            <span className={ styles.black_label }>{ appContext.t('candidate.list.headers.status') }:</span>
            <Select
                name='status'
                value={ vacancy.status }
                setValue={ () => console.log('set Value') }
                options = { catStatus }
                placeholder = { '' }
                noSearcheable
                onChange={ () => console.log('Update Status') }
                optRigth
            />
        </div>
    )

    const renderResume = () => (
        <div className={ styles.resume }>
            <div className={ styles.card }>
                <div className={ styles.title_container }>
                    { renderHeader(appContext.t('vacancy.info.title')) }
                    { renderStatus() }
                </div>
                { renderVacancyTitle() }
                { renderSection(renderList(listWorkModality), 'vacancy.info.modality.title') }
                { renderSection(renderList(listHistory), 'vacancy.info.history.title') }
            </div>
            <div className={ styles.card }>
                { renderHeader(appContext.t('vacancy.responsabilities.title')) }
                { renderSection(renderList(listEmpty)) }
            </div>
            <div className={ styles.card }>
                { renderHeader(appContext.t('candidate.resume.skills.title')) }
                { renderSection(renderList(lista), 'candidate.resume.skills.hard') }
                { renderSection(renderList(lista), 'candidate.resume.skills.soft') }
                { renderSection(renderList(listEmpty), 'candidate.resume.skills.work') }
            </div>
            <div className={ styles.card }>
                { renderHeader(appContext.t('candidate.resume.lang.title')) }
                { renderSection(renderList(languages)) }
            </div>
            <div className={ styles.card }>
                { renderHeader(appContext.t('vacancy.benefits.title')) }
                { renderSection(renderList(listEmpty)) }
            </div>
        </div>
    )

    return <div className={ styles.vacancy}>{ renderResume() }</div>
}
