
import { ReactComponent as IconCandidates } from '../../../assets/icons/menu_candidates.svg';
import { ReactComponent as IconTalents } from '../../../assets/icons/menu_talents.svg';
import { ReactComponent as IconCatalogs } from '../../../assets/icons/menu_catalogs.svg';
import { ReactComponent as IconUsers } from '../../../assets/icons/profile.svg';
import { ReactComponent as IconVacancies } from '../../../assets/icons/menu_vacancies.svg';
import { TalentList } from '../../pages/talents/components/list/TalentList';
import { SkillList } from '../../pages/candidates/components/skills/SkillList';
import { ProfileList } from '../../pages/catalogs/profiles/ProfileList';
import { ReferralsList } from '../../pages/referrarls/ReferralsList';
import { UserList } from '../../pages/catalogs/users/UserList';
import { VacancyList } from '../../pages/vacancies/VacancyList';

export const getOptions = appContext => ([
    {
        title: appContext.t('menu.candidate.title'),
        icon: <IconCandidates />,
        items: [
            {
                title: appContext.t('menu.candidate.opt1'),
                component: <TalentList />,
                onEvent: appContext.setCandidateMode,
                arg: true
            },
            {
                title: appContext.t('menu.candidate.opt2'),
                component: <ReferralsList />
            }
        ]
    },
    {
        title: appContext.t('menu.talent.title'),
        icon: <IconTalents />,
        items: [
            {
                title: appContext.t('menu.talent.opt1'),
                component: <TalentList />,
                onEvent: appContext.setCandidateMode,
                arg: false
            }
        ]
    },
    {
        title: appContext.t('menu.catalog.title'),
        icon: <IconCatalogs />,
        items: [
            {
                title: appContext.t('menu.catalog.opt1'),
                component: <SkillList />
            },
            {
                title: appContext.t('menu.catalog.opt2'),
                component: <ProfileList />
            }
        ]
    },
    {
        title: appContext.t('menu.user.title'),
        icon: <IconUsers />,
        component: <UserList />
    },
    {
        title: appContext.t('menu.vacancy.title'),
        icon: <IconVacancies />,
        component: <VacancyList />
    }
]);