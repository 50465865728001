export const BarRequestStatus = ({
    status,
    horizontal
}) => {

    const getColor = status => {
        let colorVar = '';
        switch (status) {
            case 1000200001:
            case 1000200002:
            case 1000200004:
            case 1000200005:
            case 1000200006:
            case 1000200007:
                colorVar= '--color-secondary'
                break;
            case 1000200008:
                colorVar= '--color-green'
                break;
            case 1000200009:
            case 1000200010:
                colorVar= '--color-error';
                break;
            case 1000200003:
                colorVar= '--color-recruiment-yellow';
                break;
            default:
                colorVar= '--color-recruiment-gray'
                break;
        }
        return `var(${colorVar})`;
    };

    const getNum = status => {
        let recNum = 0;
        switch (status) {
            case 1000200001:
                recNum = 1;
                break;
            case 1000200002:
                recNum = 2;
                break;
            case 1000200004:
                recNum = 3;
                break;
            case 1000200005:
                recNum = 4;
                break;
            case 1000200006:
                recNum = 5;
                break;
            case 1000200007:
                recNum = 6;
                break;
            default:
                recNum = 7;
                break;
        }
        return recNum;
    };

    const renderBars = () => [...Array(7)].map((r, index) => (
        <div
            key={ index }
            style={{
                borderRadius: '2.5px',
                width: '5px',
                height: '16px',
                backgroundColor: getColor(getNum(status)<=index ? 0 : status)
            }}
        />
    ))

    const styleHorizontal = horizontal ? { display: 'flex', gap: '4px' } : undefined

    return (
        <div style={ styleHorizontal }>{ renderBars() }</div>
    )
}
