import styles from '../../checkup.module.scss';
import stylesC from '../../../candidates/candidateInfo.module.scss';
import backArrowIcon from '../../../../../assets/icons/back-arrow.svg';
import { BarEvaluation } from '../../../../components/inputs/BarEvaluation';
import { ClimateAbout } from './ClimateAbout';
import { ClimateFollowup } from './ClimateFollowup';

export const ClimateResume = ({
    appContext,
    checkup,
    checkupErrors,
    onChangeInput,
    catLevelSatisfaction,
    tap,
    changeTap
}) => {

    const getClasificationExtraStyle = id => id === 1000580003 ? styles.desc_clasif_blue : undefined;
    const getExitRiskExtraStyle = id => id === 1000560002 ? styles.desc_risk_yellow : (id === 1000560003 ? styles.desc_risk_red : undefined);

    const renderComments = () => checkup.comments.map((c, index) => (
        <div key={ index }>
            <BarEvaluation
                title={ c.title }
                classMain={ styles.line }
                value={ c.value }
                options={ catLevelSatisfaction }
                width={ 45 }
                modeRead
            />
        </div>
    ));

    const showResume =  tap === 0 || tap === 6;

    return (
        <div className={ styles.checkup }>
            { tap === 0 && appContext.t('checkUp.climate.editLabel').replace(':name', appContext.talent.firstName) }
            <div className={styles.line}>
                <span className={ styles.label }>{ appContext.t('checkUp.climate.clasification') }:</span>
                { checkup.clasification ?
                    <div className={ `${styles.desc} ${styles.desc_clasif} ${getClasificationExtraStyle(checkup.clasification.id)}` }>
                        { checkup.clasification.optionName }
                    </div> : appContext.t('noData.unspec')
                }
            </div>
            <div className={styles.line}>
                <span className={ styles.label }>{ appContext.t('checkUp.climate.exitRisk') }:</span>
                { checkup.existRisk ?
                    <div className={ `${styles.desc} ${styles.desc_risk} ${getExitRiskExtraStyle(checkup.existRisk.id)}` }>
                        { checkup.existRisk.optionName }
                    </div> : appContext.t('noData.unspec')
                }
            </div>
            { showResume &&
                <div className={ `${styles.section} ${styles.gap8}` }>
                    <span className={ styles.label }>{ appContext.t('checkUp.climate.opinion') }:</span>
                    { checkup.comments && renderComments() }
                </div>
            }
            { tap === 6 &&
                <div className={ `${styles.section} ${styles.gap8}` }>
                    <span className={ styles.label }>{ appContext.t('checkUp.report.followLabel') }:</span>
                    { checkup.followUps && checkup.followUps.map((f, index) => (<span key={index}>{ f.value.comment }</span>)) }
                </div>
            }
            { showResume &&
                <div className={ stylesC.button_container }>
                    <div className={ stylesC.vertical_separator }></div>
                    <button className={ stylesC.add_info } onClick={() => changeTap(2)}>
                        { appContext.t('checkUp.climate.detailAbout.title') }
                        <img src={ backArrowIcon } width={12} height={12} alt="" className={ styles.rotate180 } />
                    </button>
                </div>
            }

            { tap === 2 && <ClimateAbout appContext={ appContext } comments={ checkup.comments } catLevelSatisfaction={ catLevelSatisfaction } /> }
            { tap === 0 && <ClimateFollowup appContext={ appContext } checkup={ checkup } checkupErrors={ checkupErrors } onChangeInput={ onChangeInput } /> }
        </div>
    )
}
