import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../candidates/context/AppProvider';
import { Searcher } from '../../components/talent-module/searcher/Searcher';
import styles from '../talents/talentInfo.module.scss';
import reloadIcon from '../../../assets/icons/reload.svg';
import { Pagination } from '../../components/talent-module/pagination/Pagination';
import { Loading } from '../../components/talent-module/loading/Loading';
import { SelectMultiple } from '../../components/talent-module/searcher/SelectMultiple';
import { objEquals } from '../../core/helper';
import { getCatalog, getClientCatalog } from '../../api/catalogClient';
import { defaultStringEmpty } from '../../core/talent-module/helper';
import { selectEmtpy } from '../../core/hardcode';
import { InfoMessage, InfoMessageType } from '../talents/components/misc/InfoMessage';
import { getVacancies } from '../../api/vacancyClient';
import { BarRequestStatus } from '../../components/inputs/BarRequestStatus';
import { VacancyDetail } from './components/VacancyDetail';

export const VacancyList = () => {

  const appContext = useContext(AppContext);

  const [catClient, setCatClient] = useState([]);
  const [catStatus, setCatStatus] = useState([]);
  const [modal, setModal] = useState();
  const [vacancies, setVacancies] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [showReload, setShowReload] = useState(false);

  const loadCatClient = () => {
    getClientCatalog(appContext.userData.token).then( response => {
      if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && Array.isArray(response.data.result) ) {
        let clientsTmp = [];
        response.data.result.map( l => clientsTmp.push({ id: l.id, optionName: l.name}))
        setCatClient(clientsTmp);
        loadCatStatus(clientsTmp);
    }
    }).catch( error => {
      console.log(error);
    })
  }

  const loadCatStatus = (catClient) => {
    getCatalog(1000020002, 0 ,200, '&sort=id&direction=ASC', appContext.userData?.token).then(({status, data}) => {
      if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
        let statusTmp = [];
        const content = appContext.candidateMode ? data.result.content.filter( l => !l.valueItem.includes('Talento') ) : data.result.content.filter( l => l.valueItem.includes('Talento') );
        content.map( l => {
          const status = { id: l.catalogItemId, optionName: l.valueItem }
          statusTmp.push(status);
        })
        setCatStatus(statusTmp);
        fetchVacancies(appContext.filter, appContext.clientFilter, appContext.statusFilter, appContext.visibilityFilter, appContext.currentPage, catClient, statusTmp);
        }
    }).catch(error => {
        console.log(error);
    })
  }

  const fetchVacancies = (filter, clientFilter, statusFilter, visibilityFilter, currentPage, catClient, catStatus) => {
    setTotal(0);
    setTotalPages(0);
    setVacancies([]);
    setModal(<Loading />);
    getVacancies(appContext.userData.token, filter, clientFilter, statusFilter, visibilityFilter, currentPage, 10).then( response => {
      if( response.status === 200 && response.data && response.data.result && response.data.result.content && Array.isArray(response.data.result.content) ) {
        const vacanciesTmp = [];
        response.data.result.content.map(v => {
          const client = v.clientId ? catClient.find( c=> c.id === v.clientId ) : {};
          const status = v.statusId ? catStatus.find( c=> c.id === v.statusId ) : {};
          const vacancy = {
            ...v,
            clientName: client.optionName,
            client,
            statusName: status.optionName,
            status,
            visibility: v.visibility
          }
          vacanciesTmp.push(vacancy);
        })
        setVacancies(vacanciesTmp);
        setTotalPages(response.data.result.totalPages);
        setTotal(response.data.result.totalElements);
        setShowReload(response.data.result.totalElements === 0 || filter !== '' || clientFilter.length > 0 || visibilityFilter.length > 0 );
        setModal(null);
      } else {
        setModal(null);
      }
    }).catch( error => {
      console.log(error);
      setModal(null);
    })
  }

  useEffect(() => {
    loadCatClient();
  }, [appContext.filter, appContext.clientFilter, appContext.statusFilter, appContext.visibilityFilter]);

  const onClickDetail = vacancy => {
    appContext.contentScrollUp();
    appContext.setMenuNavigation([...appContext.menuNavigation, { label: `${vacancy.leadId} ${defaultStringEmpty(vacancy.vacancyProfileName)}`}]);
    appContext.setHomeContent(<VacancyDetail vacancy={vacancy} catStatus={catStatus} />)
  }
  
  const onKeyDownSearch = ({key}) => {
    const isEmpty = key === 'Backspace' && appContext.filter.length === 1;
    if ( key === 'Enter' || isEmpty ) {
      setShowReload(!isEmpty);
      onClickSearch(isEmpty ? '' : appContext.filter);
    }
  }

  const onChangeSearch = ({target}) => {
    appContext.setFilter(target.value);
  }
  
  const onClickSearch = (filter) => {
    appContext.setCurrentPage(0);
    fetchVacancies(filter, appContext.clientFilter, appContext.statusFilter, appContext.visibilityFilter, 0, catClient, catStatus);
  }

  const reload = () => {
    setShowReload(false);
    appContext.cleanFilter();
    fetchVacancies('', [], [], [], 0, catClient, catStatus);
  }

  const onClickBackPage = () => {
    if( appContext.currentPage > 0 ) {
      appContext.setCurrentPage(appContext.currentPage - 1);
      fetchVacancies(appContext.filter, appContext.clientFilter, appContext.statusFilter, appContext.visibilityFilter, appContext.currentPage - 1, catClient, catStatus);
    }
  }

  const onClickForwardPage = cP => {
    if( appContext.currentPage + cP < totalPages ) {
      appContext.setCurrentPage(appContext.currentPage + 1);
      fetchVacancies(appContext.filter, appContext.clientFilter, appContext.statusFilter, appContext.visibilityFilter, appContext.currentPage + 1, catClient, catStatus);
    }
  }

  const onClickSelectFilter = (option, value, setValue) => {
    const newValue = value.find( v => objEquals(v,option) ) ? value.filter( v => v.id !== option.id ) : [ ...value, option ];
    setValue(newValue);
  }

  const renderSelectsFilter = () => (
    <div className={ styles.container_list_sel }>
      <SelectMultiple
        name='clientFilter'
        value = { appContext.clientFilter }
        setValue={ appContext.setClientFilter }
        options = { catClient }
        placeholder = { appContext.t('candidate.list.labelClientFilter') }
        onChange = { onClickSelectFilter }
      />
      <SelectMultiple
        name='statusFilter'
        value={ appContext.statusFilter }
        setValue={ appContext.setStatusFilter }
        options = { catStatus }
        placeholder = { appContext.t('candidate.list.headers.status') }
        onChange = { onClickSelectFilter }
      />
      <SelectMultiple
        name='visibilityFilter'
        value={ appContext.visibilityFilter }
        setValue={ appContext.setVisibilityFilter }
        options = {[
          { id: 1, optionName: appContext.t('vacancy.list.labelVisibilityOn') },
          { id: 2, optionName: appContext.t('vacancy.list.labelVisibilityOff') }
        ]}
        placeholder = { appContext.t('vacancy.list.labelVisibilityFilter') }
        onChange = { onClickSelectFilter }
      />
    </div>
  )

  const renderNotFound = () => {
    let title = '';
    let subtitles = [];
    let cssParagraph = '';
    let type = null;
    if( vacancies.length === 0 && appContext.filter === '' && appContext.clientFilter.length === 0 && appContext.statusFilter.length === 0 && appContext.visibilityFilter.length === 0) {
      title = appContext.t('vacancy.list.notFound')
      subtitles.push(appContext.t('candidate.notFound.subtitle'));
      subtitles.push(appContext.t('candidate.notFound.detail'));
      type = InfoMessageType.notFound;
      cssParagraph = styles.pharagrap_multiple;
    } else if (vacancies.length === 0 && appContext.filter !== '' || appContext.clientFilter.length > 0 || appContext.statusFilter.length > 0 || appContext.visibilityFilter.length > 0) {
      title = appContext.t('notMatch.title');
      subtitles.push(appContext.t('notMatch.subtitle'));
      type = InfoMessageType.notMatch;
      cssParagraph = styles.pharagrap_simple;
    }
    return <InfoMessage title={ title } subtitles={ subtitles } type={ type } cssParagraph={ cssParagraph } shadow />
  }

  const renderItems = () => vacancies.map((vacancy, index) => (
    <tr key={ index } onClick={ () => onClickDetail(vacancy) }>
      <td className={ styles.first_child_border }>{ vacancy.leadId }</td>
      <td>
        <div className={ styles.td_column } style={{ marginLeft: '24px' }}>
          <label className={ styles.name }>{ defaultStringEmpty(vacancy.vacancyProfileName) }</label>
          <label className={ styles.font_secondary }>{ defaultStringEmpty(vacancy.client ? vacancy.clientName : '') }</label>
        </div>
      </td>
      <td className={ styles.textleft }>
        <div className={ styles.td_column }>
          <label>{ defaultStringEmpty(vacancy.status ? vacancy.statusName : '') }</label>
          <BarRequestStatus status={vacancy.statusId} horizontal />
        </div>
      </td>
      <td className={ styles.textcenter }>
        <span className={`${styles.fontwhite} ${styles.border} ${vacancy.visibility.includes('No') ? styles.border_gray : styles.border_green}`}>{ vacancy.visibility }</span>
      </td>
      <td className={ styles.textcenter }>{ vacancy.creationDate }</td>
      <td className={ styles.last_child_border }>{ vacancy.authorName }</td>
    </tr>
  ));

  const renderTable = () => (
    <table>
      <thead>
        <tr>
          <th className={ styles.first_child_border }>{appContext.t('vacancy.list.headers.id')}</th>
          <th>{appContext.t('vacancy.list.headers.name')}</th>
          <th style={{ width: '15%' }}>{appContext.t('candidate.list.headers.status')}</th>
          <th style={{ width: '17%' }}>{appContext.t('vacancy.list.labelVisibilityFilter')}</th>
          <th style={{ width: '10%' }}>{appContext.t('candidate.list.headers.date')}</th>
          <th className={ styles.last_child_border }>{appContext.t('vacancy.list.headers.sent')}</th>
        </tr>
      </thead>
      <tbody>
        { renderItems() }
      </tbody>
    </table>
  )

  const buildLabelResult = () => {
    const entityString = appContext.t('menu.vacancy.title').toLowerCase();
    let complementString = appContext.filter !== '' || appContext.clientFilter.length > 0 || appContext.visibilityFilter.length > 0 ? appContext.t('candidate.list.labelNoFound') : '';
    if( total === 1 && complementString.endsWith('s') ) {
      complementString = complementString.substring(0, complementString.length-1);
    }
    return `${entityString} ${complementString}`;
  }

  return (
    <div className={ styles.container } >
      <div className={ styles.wrapper }>
        <div className={ styles.container_list_btn }>
          <label className={ styles.title }>{ appContext.t('menu.vacancy.title') }</label>
        </div>
        <div className={ styles.container_list_btn }>
          <div className={ styles.btn_label }>
            <div className={ styles.width_40 }>
              <Searcher 
                name={'filter'} 
                value={ appContext.filter } 
                placeholder={ appContext.t('vacancy.list.labelFilter') }
                onChange={ onChangeSearch }
                onKeyDown={ onKeyDownSearch }
                onClick={ () => onClickSearch(appContext.filter) }
              />
            </div>
            <label>{ `${total} ${ buildLabelResult() }` }</label>
            { showReload && !modal &&  <div className={ styles.separator } /> }
            {
              showReload && !modal && (
                <div className={ styles.reload } onClick = { () => reload() }>
                  <img src={ reloadIcon } alt='' />
                  <label>{ appContext.t('vacancy.list.labelReload') }</label>
                </div>
              )
            }
          </div>
        </div>
        { renderSelectsFilter() }
        { renderTable() }
        { !modal && vacancies.length === 0 && renderNotFound() }
        { modal }
        <Pagination
          totalElements={ vacancies.length }
          currentPage={ appContext.currentPage }
          totalPages={ totalPages }
          onClickBackPage={ onClickBackPage }
          onClickForwardPage={ onClickForwardPage }
        />
      </div>
    </div>
    
  )
}
